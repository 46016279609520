import { Controller } from "stimulus";
import Rails from "rails-ujs";

export default class extends Controller {
  static targets = ["loader", "connect"];

  connect() {
    let data = this.getCookie("_linked");
    if (document.getElementById("email_id").value === "null" || data === "") {
      $("#show-div").addClass("d-none");
      window.location.href = "/connect_linkedin";
    }
  }
  displayPassword() {
    var input_field = document.getElementById("pass_log_id");
    if (input_field.type === "password") {
      input_field.type = "text";
    } else {
      input_field.type = "password";
    }
  }

  getCookie(cname) {
    let name = cname + "=";

    var splitCookie = document.cookie.split(";");
    // let splitCookie = decodedCookie.split(';');
    for (let count = 0; count < splitCookie.length; count++) {
      let splitted = splitCookie[count];
      while (splitted.charAt(0) == " ") {
        splitted = splitted.substring(1);
      }
      if (splitted.indexOf(name) == 0) {
        return splitted.substring(name.length, splitted.length);
      }
    }
    return "";
  }

  validateEmail() {
    this.connectTarget.disabled = true;
    var emailAddress = $("#email_id").val();
    if (!validateEmail(emailAddress)) {
      $("#email_alert").removeClass("d-none");
    } else {
      $("#email_alert").addClass("d-none");
      this.connectTarget.disabled = false;
    }
    function validateEmail($email) {
      var regex =
        /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!regex.test($email)) {
        return false;
      } else {
        return true;
      }
    }
  }
  
  validatePassword() {
    this.connectTarget.disabled = true;
    var password = $("#pass_log_id").val();
    if (validatePassword(password)) {
      $("#password_alert").addClass("d-none");
      if (this.validateEmail()) {
        this.connectTarget.disabled = false;
      }
    } else {
      $("#password_alert").removeClass("d-none");
    }
    function validatePassword($password) {
      if ($password.length > 5) {
        return true;
      } else {
        return false;
      }
    }
  }
}
