import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ['BilForm','date']

  connect(){
   console.log("billing connected")
   this.dateTarget.flatpickr({mode: "range"});
  }

  getBill()
  {
    Rails.fire(this.BilFormTarget, 'submit')
  }
}