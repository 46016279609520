import { Controller } from "stimulus";
import Rails from "rails-ujs";
import Choices from "choices.js";
import _ from "underscore";
import Swal from "sweetalert2/dist/sweetalert2.js";
import consumer from "../../channels/consumer";
import { Modal } from "bootstrap";
import * as bootstrap from 'bootstrap'

var allIds = 0;
export default class extends Controller {
  static targets = [
    "clientsList",
    "searchField",
    "searchForm",
    "loader",
    "filtersModal",
    "industrySpinner",
    "industryDropdown",
    "geoDropdown",
    "seniorLevelDropdown",
    "headcountDropdown",
    "functionDropdown",
    "roleDropdown",
    "filterButton",
    "creditToken",
    "checkbox",
    "subscribedBtn",
    "load",
    "leadsTableHead",
    "exportBtn",
    "exportSpreadsheetBtn",
    "leadsTableData",
  ];

  connect() {
    this.createDropdowns();
    this.loaderTarget.classList.remove("d-none");

    this.optionsLoading = false;
    let _this = this;

    $(".choices-box .choices__input").keyup(
      _.debounce(function (event) {
        let el = event.currentTarget;
        let select = el.parentElement.querySelector("select");
        _this.insertChoices(el, select);
        _this.clearOptions(select);
      }, 500)
    );
    window.addEventListener("load", (event) => {
      setTimeout(this.bind, 5000);
    });

    let room_name = document.querySelector("#tar-body").dataset.clientName;

    var consumerChannel = consumer.subscriptions.create(
      { channel: "TargetChannel", room: room_name },
      {
        connected(res) {
          // Called when the subscription is ready for use on the server
          $.ajax({
            url: window.location,
            type: "GET",
            dataType: "json",
            success: () => {
              console.log("sent");
            },
          });
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          _this.loaderTarget.classList.add("d-none");
          _this.clientsListTarget.innerHTML = data.body;
          var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
          })
          consumerChannel.unsubscribe();
          // Called when there's incoming data on the websocket for this channel
        },
      }
    );
  }
  
  bind() {
    var myModal = document.getElementById("leadsDataModal");
    myModal.addEventListener("hide.bs.modal", function (event) {
      window.location.href = "/targeting";
    });
  }

  createDropdowns() {
    this.options = {
      addItems: true,
      removeItems: true,
      removeItemButton: true,
      searchChoices: true,
      duplicateItemsAllowed: false,
    };

    this.industryDropdown = new Choices(
      this.industryDropdownTarget,
      this.options
    );

    this.geoDropdown = new Choices(this.geoDropdownTarget, this.options);

    this.seniorLevelDropdown = new Choices(
      this.seniorLevelDropdownTarget,
      this.options
    );

    this.headcountDropdown = new Choices(
      this.headcountDropdownTarget,
      this.options
    );

    this.functionDropdown = new Choices(
      this.functionDropdownTarget,
      this.options
    );

    this.roleDropdown = new Choices(this.roleDropdownTarget, this.options);
  }

  insertChoices(el, select) {
    let _this = this;
    // Fetch options with ajax call
    if (_this.optionsLoading) return;

    _this.optionsLoading = true;
    el.readOnly = true;

    _this.toggleSpinner(select);

    Rails.ajax({
      url: `/targeting/get_options?column=${select.dataset.dropdown}&value=${el.value}`,
      type: "get",
      data: "",
      success: (res) => {
        _this.optionsLoading = false;
        if (res.options.length > 0) {
          switch (select.dataset.dropdown) {
            case "geo":
              _this.geoDropdown.setChoices(
                res.options,
                "value",
                "label",
                false
              );
              break;
            case "role":
              _this.roleDropdown.setChoices(
                res.options,
                "value",
                "label",
                false
              );
              break;
          }
        }

        _this.toggleSpinner(select);
        el.readOnly = false;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  toggleSpinner(select) {
    $(`.${select.dataset.dropdown}-spinner`).toggleClass("d-none");
  }

  clearOptions(select) {
    switch (select.dataset.dropdown) {
      case "industry":
        this.industryDropdown.clearChoices();
        break;
      case "geo":
        this.geoDropdown.clearChoices();
        break;
      case "senior_level":
        this.seniorLevelDropdown.clearChoices();
        break;
      case "function":
        this.functionDropdown.clearChoices();
        break;
      case "headcount":
        this.headcountDropdown.clearChoices();
        break;
      case "role":
        this.roleDropdown.clearChoices();
        break;
    }
  }

  getLeads() {
    this.loaderTarget.classList.remove("d-none");
    Rails.fire(this.searchFormTarget, "submit");
  }

  saveQuery() {
    var _data = new FormData(this.searchFormTarget);

    Rails.ajax({
      url: `/targeting/save_query`,
      type: "post",
      data: _data,
      success: function (res) {
        if (res.status == 200) {
          Swal.fire({
            title: "Success!",
            text: "saved",
            icon: "success",
          });
        }
      },
    });
  }

  unlock(event) {
    const id = event.currentTarget.id.split("-")[0];
    document.getElementById(id).checked = true;
    this.subscribedLead();
  }

  allCheck(event) {
    const subscribedIds = event.currentTarget.dataset["id"];
    var arrayId = JSON.parse(subscribedIds);
    var dataArr = document.getElementsByClassName("leads-check-input");
    Array.from(dataArr).map((d) => {
      if (event.currentTarget.checked) {
        d.checked = true;
        this.subscribedBtnTarget.disabled = false;
        allIds = arrayId;
      } else {
        d.checked = false;
        this.subscribedBtnTarget.disabled = true;
        allIds = 0;
      }
    });
  }

  singleCheck(event) {
    var id = event.currentTarget.dataset["id"];
    var dataArr = document.getElementsByClassName("leads-check-input");
    var currentCheck = document.getElementById(id);
    if (currentCheck.checked) {
      this.subscribedBtnTarget.disabled = false;
    } else {
      if (!Array.from(dataArr).some((d) => d.checked))
        this.subscribedBtnTarget.disabled = true;
      document.getElementById("allCheck").checked = false;
    }
  }
  close() {
    window.location.href = "/targeting";
  }

  previewCsvLeads(event) {
    var _this = this;
    let query = ""
    let subscribedIds;
    _this.loaderTarget.classList.remove("d-none");

    if(event.currentTarget.dataset.value == 'selected'){
      subscribedIds = 0;
      if (allIds !== 0) {
        subscribedIds = allIds;
      } else {
        var dataArr = document.getElementsByClassName("id");
        var a = [];
        Array.from(dataArr).map((d) => {
          if (d.checked) {
            a.push(d.value);
          }
        });
        subscribedIds = a.map((i) => Number(i));
      }
    }
    else {
      query = $(".query-string").text()
      subscribedIds = 'all'
    }

    $.ajax({
      url: "/targeting/preview_leads",
      type: "GET",
      dataType: "json",
      data: { subscribedIds: subscribedIds, query: query },
    })
      .done(function (data) {
        if (data.status == 404) {
          window.location.href = data.path;
        } else {
          _this.exportSpreadsheetBtnTarget.href = `/targeting/export_spreadsheet.xlsx?subscribedIds=${subscribedIds}`
          var myModal = new Modal(document.getElementById("leadsDataModal"));
          myModal.show();
          _this.loaderTarget.classList.add("d-none");
          _this.leadsTableDataTarget.innerHTML = data.reportData;
          _this.leadsTableHeadTarget.innerHTML = data.reportHead;
          _this.exportBtnTarget.href = data.filePath;
        }
      })
      .fail(function (error) {
        console.log(error.responseText);
      });
  }

}
