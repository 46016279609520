import { Controller } from "stimulus";
import Rails from "rails-ujs";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "tableColumns",
    "selectedColumns",
    "tableSelect",
    "addBtn",
    "exportBtn",
    "previewBtn",
    "dateRange",
    "columnNames",
    "reportsTableData",
    "reportsTableHead",
    "loader",
    "selAll",
    "selectAllCheckbox",
    "clientName",
  ];

  connect() {
    this.dateRangeTarget.flatpickr({ mode: "range" });
  }

  getTableColumns() {
    var CI = this;
    CI.previewBtnTarget.disabled = true;
    CI.addBtnTarget.disabled = true;

    $.ajax({
      url: "/reports/select_table",
      type: "GET",
      dataType: "json",
      data: { table_name: this.tableSelectTarget.value },
    })
      .done(function (data) {
        CI.selectAllCheckboxTarget.checked = false;
        CI.tableColumnsTarget.innerHTML = data.pageHTML;
        if (data.columns.includes("date")) {
          CI.dateRangeTarget.classList.remove("d-none");
        } else {
          CI.dateRangeTarget.classList.add("d-none");
        }
        CI.selAllTarget.classList.remove("d-none");
      })
      .fail(function (error) {
        console.log(error.responseText);
      });

    this.columnNamesTarget.value = "";
    CI.selectedColumnsTarget.innerHTML =
      "<p>Please select columns to export</p>";
  }

  selectedColumns() {
    let checkedBoxes = Array.from(
      this.tableColumnsTarget.querySelectorAll("input[type='checkbox']:checked")
    );
    let allCheckBoxes = Array.from(
      this.tableColumnsTarget.querySelectorAll("input[type='checkbox']")
    );

    if (checkedBoxes.length > 0) {
      this.addBtnTarget.disabled = false;
    } else {
      this.addBtnTarget.disabled = "true";
    }
    if (checkedBoxes.length < allCheckBoxes.length) {
      this.selectAllCheckboxTarget.checked = false;
    }
  }

  addColumns() {
    var columns = Array.from(
      this.tableColumnsTarget.querySelectorAll("input[type='checkbox']:checked")
    ).map(function (input) {
      return input;
    });
    if (columns.length > 0) {
      this.previewBtnTarget.disabled = false;
    }
    var selectedColumns = "";
    columns.forEach(function (column, index) {
      selectedColumns += `<div><p class='text-capitalize mb-2'>${column.id}</p></div>`;
    });
    this.selectedColumnsTarget.innerHTML = selectedColumns;
    this.previewBtnTarget.disabled = false;
    this.getColumnNames(columns);
  }

  getColumnNames(columns) {
    var selectedColumnNames = columns
      .map(function (input) {
        return input.id;
      })
      .join();
    this.columnNamesTarget.value = selectedColumnNames;
  }

  previewReport() {
    var CI = this;
    CI.loaderTarget.classList.remove("d-none");
    var clientName;
    if (this.hasClientNameTarget) {
      clientName = this.clientNameTarget.value;
    } else {
      clientName = "";
    }
    $.ajax({
      url: "/reports/preview_report",
      type: "GET",
      dataType: "json",
      data: {
        table_name: CI.tableSelectTarget.value,
        column_names: CI.columnNamesTarget.value,
        daterange: CI.dateRangeTarget.value,
        client_name: clientName,
      },
    })
      .done(function (data) {
        var myModal = new Modal(document.getElementById("reportsDataModal"));
        myModal.show();
        CI.loaderTarget.classList.add("d-none");
        CI.reportsTableDataTarget.innerHTML = data.reportData;
        CI.reportsTableHeadTarget.innerHTML = data.reportHead;
        CI.exportBtnTarget.href = data.filePath;
      })
      .fail(function (error) {
        console.log(error.responseText);
      });
  }

  selectAll(event) {
    var checkBoxDiv = document.getElementById("checkBoxDiv");
    var checkBoxes = checkBoxDiv.getElementsByClassName("form-check-input");
    var check = event.currentTarget.checked;

    for (var checkbox of checkBoxes) {
      checkbox.checked = true;
    }
    this.selectAllCheckboxTarget.checked = true;
    this.addBtnTarget.disabled = false;
  }
}
