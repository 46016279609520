import { Controller } from 'stimulus'
import Shepherd from 'shepherd.js';

export default class extends Controller {

  static values = {
    subscription: String
  }

  connect(){
    if(this.getCookie("targeting_tour") == ""){
      this.setTour()
    }
  }

  setTour(){
    let _this = this
    if( this.subscriptionValue == "true" ){
      const tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
          classes: 'shadow-md bg-purple-dark',
          scrollTo: { behavior: 'smooth', block: 'center' }
        }
      });
  
      tour.addSteps([{
        id: 'first-step',
        text: 'Select campaign from the dropdown to view campaign leads and update targeting.',
        attachTo: {
          element: '.campaign-dropdown',
          on: 'bottom'
        },
        classes: 'rounded',
        buttons: [
          {
            text: 'Cancel',
            action: tour.cancel
          },
          {
            text: 'Next',
            action: tour.next
          }
        ]
      },
      {
        id: 'second-step',
        text: 'Apply filters to get required targeting.',
        attachTo: {
          element: '.filter-btn',
          on: 'bottom'
        },
        classes: 'rounded',
        buttons: [
          {
            text: 'Back',
            action: tour.back
          },
          {
            text: 'Next',
            action: tour.next
          }
        ]
      },
      {
        id: 'third-step',
        text: 'Select lead(s) to export.',
        attachTo: {
          element: '.campaign-lead',
          on: 'bottom'
        },
        classes: 'rounded',
        buttons: [
          {
            text: 'Back',
            action: tour.back
          },
          {
            text: 'Next',
            action: tour.next
          }
        ]
      }]);

      if($('.targeting-creation-btn').length > 0){
        tour.addStep({
          id: 'fourth-step',
          text: 'Click "Apply Targeting For Creation" to update your campagin targeting.',
          attachTo: {
            element: '.targeting-creation-btn',
            on: 'bottom'
          },
          classes: 'rounded',
          buttons: [
            {
              text: 'Back',
              action: tour.back
            },
            {
              text: 'Next',
              action: tour.next
            }
          ]
        })
      }

      tour.addStep({
        id: 'fifth-step',
        text: 'Click Downnload Leads to export selected or all leads(upto 10,000).',
        attachTo: {
          element: '.export-leads-btn',
          on: 'right'
        },
        classes: 'rounded',
        buttons: [
          {
            text: 'cancel',
            action: tour.cancel
          },
          {
            text: 'Finish',
            action() {
              _this.tourCompleteCookie()
              return this.next();
            }
          }
        ]
      });
  
      tour.start();
    }
  }

  tourCompleteCookie(){
    var cookieName = "targeting_tour";
    var myDate = new Date();
    myDate.setHours(myDate.getHours() + 8760);
    document.cookie = cookieName + "=" + "completed=true;expires=" + myDate + ";domain=leadrequests.com;path=/";
  }

  getCookie(cname) {
    let name = cname + "=";

    var splitCookie = document.cookie.split(";");
    // let splitCookie = decodedCookie.split(';');
    for (let count = 0; count < splitCookie.length; count++) {
      let splitted = splitCookie[count];
      while (splitted.charAt(0) == " ") {
        splitted = splitted.substring(1);
      }
      if (splitted.indexOf(name) == 0) {
        return splitted.substring(name.length, splitted.length);
      }
    }
    return "";
  }
}
