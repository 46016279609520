import { Controller } from "stimulus";
import Rails from "rails-ujs";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default class extends Controller {
  static targets = ["loader", "linkedInLoader", "linkedAllianceLoader"];

  connect() {}

  linkedInSubscription() {
    this.linkedInLoaderTarget.classList.remove("d-none");
    Rails.ajax({
      type: "GET",
      url: `/connect_linkedin_subscription/update_subscription`,
      success: (res) => {
        this.linkedInLoaderTarget.classList.add("d-none");
        if (res.status == 404) {
          window.location.href = res.path;
        }
        if (res.status == 200) {
          Swal.fire({
            title: "Success!",
            text: "Subscription unsubscribed",
            icon: "success",
          });
          window.location.href = res.path;
        }
      },
    });
  }

  linkedAllianceSubscription() {
    this.linkedAllianceLoaderTarget.classList.remove("d-none");
    Rails.ajax({
      type: "GET",
      url: `/linkedalliance_subscription/update_subscription`,
      success: (res) => {
        this.linkedAllianceLoaderTarget.classList.add("d-none");
        if (res.status == 404) {
          window.location.href = res.path;
        }
        if (res.status == 200) {
          Swal.fire({
            title: "Success!",
            text: "Subscription unsubscribed",
            icon: "success",
          });
          window.location.href = res.path;
        }
      },
    });
  }

  linkedInResubscription() {
    this.linkedInLoaderTarget.classList.remove("d-none");
    Swal.fire({
      title: "Resubscribe Subscription",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Resubscribe",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Rails.ajax({
          type: "POST",
          url: `/connect_linkedin_plan/subscription`,
          data: "interval=check",
          dataType: "json",
          success: (res) => {
            this.linkedInLoaderTarget.classList.add("d-none");
            if (res.status == 404) {
              window.location.href = res.path;
            }
          },
        });
      } else {
        this.linkedInLoaderTarget.classList.add("d-none");
      }
    });
  }

  linkedAllianceReSubscription() {
    this.linkedAllianceLoaderTarget.classList.remove("d-none");
    Swal.fire({
      title: "Resubscribe Subscription",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Resubscribe",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Rails.ajax({
          type: "POST",
          url: `/linkedalliance_plan/subscription`,
          data: "interval=check",
          dataType: "json",
          success: (res) => {
            this.linkedAllianceLoaderTarget.classList.add("d-none");
            if (res.status == 404) {
              window.location.href = res.path;
            }
          },
        });
      } else {
        this.linkedAllianceLoaderTarget.classList.add("d-none");
      }
    });
  }

  linkedInCardUpdate() {
    this.linkedInLoaderTarget.classList.remove("d-none");
    Rails.ajax({
      type: "POST",
      url: `connect_linkedin_plan/update_card`,
      data: "interval=check",
      dataType: "json",
      success: (res) => {
        this.linkedInLoaderTarget.classList.add("d-none");
        if (res.status == 404) {
          window.location.href = res.path;
        }
      },
    });
  }

  linkedAllianceCardUpdate() {
    this.linkedAllianceLoaderTarget.classList.remove("d-none");
    Rails.ajax({
      type: "POST",
      url: `linkedalliance_plan/update_card`,
      data: "interval=check",
      dataType: "json",
      success: (res) => {
        this.linkedAllianceLoaderTarget.classList.add("d-none");
        if (res.status == 404) {
          window.location.href = res.path;
        }
      },
    });
  }
  subscription() {
    this.loaderTarget.classList.remove("d-none");
    Rails.ajax({
      type: "GET",
      url: `/subscription/update_subscription`,
      success: (res) => {
        this.loaderTarget.classList.add("d-none");
        if (res.status == 404) {
          window.location.href = res.path;
        }
        if (res.status == 200) {
          Swal.fire({
            title: "Success!",
            text: "Subscription unsubscribed",
            icon: "success",
          });
          window.location.href = res.path;
        }
      },
    });
  }

  resubscription() {
    this.loaderTarget.classList.remove("d-none");
    Swal.fire({
      title: "Resubscribe Subscription",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Resubscribe",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Rails.ajax({
          type: "POST",
          url: `plan/subscription`,
          data: "interval=check",
          dataType: "json",
          success: (res) => {
            this.loaderTarget.classList.add("d-none");
            if (res.status == 404) {
              window.location.href = res.path;
            }
          },
        });
      } else {
        this.loaderTarget.classList.add("d-none");
      }
    });
  }

  cardUpdate() {
    this.loaderTarget.classList.remove("d-none");
    Rails.ajax({
      type: "POST",
      url: `plan/update_card`,
      data: "interval=check",
      dataType: "json",
      success: (res) => {
        this.loaderTarget.classList.add("d-none");
        if (res.status == 404) {
          window.location.href = res.path;
        }
      },
    });
  }
}
