import { Controller } from "stimulus";
import Rails from "rails-ujs";

export default class extends Controller {
  static targets = [];

  connect() {}

  redirectSubscription() {
    // window.location.href = "/profile";
    Rails.ajax({
      type: "POST",
      url: `plan/update_card`,
      data: "interval=check",
      dataType: "json",
      success: (res) => {
        if (res.status == 404) {
          window.location.href = res.path;
        }
      },
    });
  }
  redirectLinkedInSubscription() {
    Rails.ajax({
      type: "POST",
      url: `connect_linkedin_plan/update_card`,
      data: "interval=check",
      dataType: "json",
      success: (res) => {
        if (res.status == 404) {
          window.location.href = res.path;
        }
      },
    });
  }
  redirectLinkedAllianceSubscription() {
    Rails.ajax({
      type: "POST",
      url: `linkedalliance_plan/update_card`,
      data: "interval=check",
      dataType: "json",
      success: (res) => {
        if (res.status == 404) {
          window.location.href = res.path;
        }
      },
    });
  }
}
