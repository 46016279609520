import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = [
    'form',
  ]
  update(){
    console.log(this.formTarget)
    Rails.fire(this.formTarget, 'submit')
  }
}
