import { Controller } from "stimulus";
import Rails from "rails-ujs";
import Swal from "sweetalert2/dist/sweetalert2.js";
import _ from "lodash";
import { object } from "underscore";
import $ from 'jquery';
import "parsleyjs/dist/parsley.js";
export default class extends Controller {
  static values = {
    subscription: String,
    data: String
  }

  static targets = [
    "loader",
    "alert",
    "loading",
    "tick",
    "confirmation",
    "notifier",
    "addLinked",
    "addLinkedAccount",
    "createCampaignButton",
    "userName",
    "password",
    "campaign",
    "active",
    "linkedinCampaignForm",
    "campaginName",
    "inboxName",
    "clientEmail",
    "extensionBtn",
  ];

  connect() {
    this.stepnum = 0
    this.opacity

    if(this.subscriptionValue == "true" && this.dataValue == "false" ){ 
      Swal.fire({
        title: 'Thank You!',
        text: 'Your have succefully subscribed to the subscription',
        icon: 'success',
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Next',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.nextStep(0)
        }
      })
    } else if(this.dataValue == "false") {
      $(document.querySelectorAll("fieldset")[0]).show()
    }

    this.confirmationTarget.disabled = true;
    this.addLinkedAccountTarget.disabled = true;
    this.createCampaignButtonTarget.disabled = true;
    const progress = document.getElementById("progress");
    // document.getElementById("campaign_name").disabled = true;

    var _this = this;
    var current_fs, next_fs, previous_fs; //fieldsets
    var current = 1;
    let steps = $("fieldset").length;
    
    $(".next").click(function () {
      _this.nextStep()
    });
  }
  
  validateEmailRegex($email) {
    var regex =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!regex.test($email)) {
      return false;
    } else {
      return true;
    }
  }

  addCampaignMessages(){
    let _this = this
    $(_this.linkedinCampaignFormTarget).parsley().whenValidate({
      group: 'block-1'
    })
    .done(()=>{
      _this.nextStep()
    })
  }

  saveCampaign(){
    this.loaderTarget.classList.remove("d-none");
    Rails.fire(this.linkedinCampaignFormTarget, 'submit')
  }

  handleResults(){
    this.loaderTarget.classList.add("d-none");
    Swal.fire({
      title: 'Campaign Setup Complete',
      text: 'Information on it will take 24-48h collecting leads and then will begin',
      icon: 'success',
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: 'Ok',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.href = "/overview"
      }
    })
  }
  
  validatePasswordRegex($password) {
    if ($password.length > 5) {
      return true;
    } else {
      return false;
    }
  }

  updateProgressbar(stepnum) {
    let progressSteps = document.querySelectorAll(".progress-step");

    progressSteps.forEach((progressStep, idx) => {
      if (idx < stepnum + 1) {
        progressStep.classList.add("progress-step-active");
      } else {
        progressStep.classList.remove("progress-step-active");
      }
    });
  }

  nextStep(n=0){
    let _this = this
    this.stepnum = this.stepnum + n;
    this.stepnum++
    this.updateProgressbar(this.stepnum);
    _this.current_fs = $(document.querySelectorAll("fieldset")[this.stepnum - 1])
    _this.next_fs = $(document.querySelectorAll("fieldset")[this.stepnum])
    //Add Class Active
    $("#progressbar .progress-step")
      .eq($("fieldset").index(this.next_fs))
      .addClass("active");

    //show the next fieldset
    this.next_fs.show();
    //hide the current fieldset with style
    this.current_fs.animate(
      { opacity: 0 },
      {
        step: function (now) {
          // for making fielset appear animation
          _this.opacity = 1 - now;

          _this.current_fs.css({
            display: "none",
            position: "relative",
          });
          _this.next_fs.css({ opacity: _this.opacity });
        },
        duration: 500,
      }
    );
  }

  checkExtension() {
    var _this = this;
    var extensionId = "gjhhaogopobjmnidhemhjllagofpegln";
    if(chrome.runtime){
      chrome.runtime.sendMessage(
        extensionId,
        { message: "version" },
        function (reply) {
          console.log(reply)
          if (reply !== undefined) {
            if (reply.version !== '') {
              let email = document.getElementById("email_id").value
              let password = document.getElementById("pass_log_id").value
              let campaign = document.getElementById("campaign_name").value
              document.getElementById("campaign_id_name").innerHTML = campaign
              document.getElementById("email_id_name").innerHTML = email
              document.getElementById("pass_log_name").innerHTML = password
              _this.createCookie(email, password, campaign);
              _this.nextStep()
            }
          }
          else{
            _this.downloadExtensionAlert()
          }
        }
      );
    } else{
      _this.downloadExtensionAlert()
    }
  }

  downloadExtensionAlert(){
    Swal.fire({
      title: 'Error',
      text: 'Please install the extension before continue',
      icon: 'info',
      allowOutsideClick: true,
      allowEscapeKey: false,
      confirmButtonText: 'Download',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.open('https://chrome.google.com/webstore/detail/leadrequests/gjhhaogopobjmnidhemhjllagofpegln', '_blank').focus()
      }
    })
  }

  extenionCheck(){
    this.extensionBtnTarget.click();
  }

  connectLinkedIn(){
    this.activeTarget.click();
    this.checkResponse()
  }

  checkResponse(){
    let _this = this
    if( _this.activeTarget.dataset.resp == 'false'){
      setTimeout(function(){
        _this.checkResponse()
      }, 500)
    }
    else {
      let response = JSON.parse( _this.activeTarget.dataset.resp).res
      _this.campaginNameTarget.value = response.account_data.ClientName
      _this.inboxNameTarget.value = response.account_data.ClientName
      _this.clientEmailTarget.value = response.account_data.email
      Swal.fire({
        title: 'Thank You!',
        text: 'Your account have been successfully linked with the campaign',
        icon: 'success',
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Add Messages',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          _this.nextStep()
        }
      })
    }
  }

  nextBtnExtension() {
    this.checkExtension();
  }

  createCookie(email, password, campaign) {
    var _this = this;
    let token = $("meta[name='csrf-token']").attr("content")
    this.loaderTarget.classList.remove("d-none");
    $.ajax({
      type: "POST",
      url: `/connect_linkedin/create_cookie`,
      data: { email: email, password: password, campaign: campaign, authenticity_token: token},
      dataType: "json",
      success: (res) => {
        _this.loaderTarget.classList.add("d-none");
        if (res.status == 400) {
          _this.notifierTarget.classList.remove("d-none");
          // location.reload();
        } else if (res.status == 200) {
          _this.userNameTarget.innerHTML = res.username;
          _this.passwordTarget.innerHTML = res.password;
          _this.campaignTarget.innerHTML = res.campaign;
        }
      },
    });
  }

  connectAccount() {
    this.loaderTarget.classList.remove("d-none");
    Rails.ajax({
      type: "POST",
      url: `/connect_linkedin_plan/subscription`,
      data: "interval=linkedin_month",
      dataType: "json",
      success: (res) => {
        this.loaderTarget.classList.add("d-none");
      },
    });
  }

  toggleAlert() {
    let campaign_name_length =
      document.getElementById("campaign_name").value.length;
    this.tickTarget.classList.add("d-none");
    if (campaign_name_length < 3 && campaign_name_length > -1) {
      this.alertTarget.innerHTML = "Too Short";
      this.alertTarget.classList.remove("d-none");
    } else {
      this.alertTarget.innerHTML = "Campaign name already Exist";
      this.alertTarget.classList.add("d-none");
      this.debouncedCampaign();
    }
  }

  debouncedCampaign = _.debounce(this.campaignNameCheck, 1000);

  campaignNameCheck() {
    let clientName = document.getElementById("campaign_name").value;
    this.loadingTarget.classList.remove("d-none");
    var _this = this;
    $.ajax({
      url: `/connect_linkedin/valid_client_name`,
      type: "GET",
      dataType: "json",
      data: { clientName: clientName },
    })
      .done(function (data) {
        if (data.status == 200) {
          _this.loadingTarget.classList.add("d-none");
          _this.tickTarget.classList.remove("d-none");
          _this.createCampaignButtonTarget.disabled = false;
        } else {
          _this.tickTarget.classList.add("d-none");
          _this.loadingTarget.classList.add("d-none");
          _this.alertTarget.classList.remove("d-none");
        }
      })
      .fail(function (error) {
        console.log(error.responseText);
      });
  }

  validateEmail() {
    var emailAddress = $("#email_id").val();
    if (! this.validateEmailRegex(emailAddress)) {
      $("#email_alert").removeClass("d-none");
    } else {
      $("#email_alert").addClass("d-none");
      return true;
    }
  }
  
  validatePassword() {
    var password = $("#pass_log_id").val();
    if (this.validatePasswordRegex(password)) {
      $("#password_alert").addClass("d-none");
      if (this.validateEmail()) {
        this.confirmationTarget.disabled = false;
      }
    } else {
      $("#password_alert").removeClass("d-none");
      this.confirmationTarget.disabled = true;
    }
  }

  connectBtn() {
    var check = this.confirmationTarget;
    if (check.checked) {
      this.addLinkedAccountTarget.classList.remove("next-btn");
      this.addLinkedAccountTarget.disabled = false;
    } else {
      this.addLinkedAccountTarget.classList.add("next-btn");
      this.addLinkedAccountTarget.disabled = true;
    }
  }
}
