import { Controller } from "stimulus";
import Rails from "rails-ujs";
import Swal from "sweetalert2/dist/sweetalert2.js";
import _ from "lodash";
import $ from 'jquery';
import "parsleyjs/dist/parsley.js";

export default class extends Controller {
  static values = {
    subscription: String,
    data: String
  }

  static targets = [
    "alert",
    "loading",
    "tick",
    "loader",
    "campaignForm",
    "clientEmail",
    "campaignName",
    "campaignStatus",
    "clientName"
  ];

  connect() {
    this.stepnum = 0
    this.opacity

    if(this.subscriptionValue == "true" && this.dataValue == "false" ){ 
      Swal.fire({
        title: 'Thank You!',
        text: 'Your have succefully subscribed to the subscription',
        icon: 'success',
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Next',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.nextStep(0)
        }
      })
    } else if(this.dataValue == "false") {
      this.delete_cookie("connected_campaign")
      $(document.querySelectorAll("fieldset")[0]).show()
    }

    var _this = this;
    var current_fs, next_fs, previous_fs; //fieldsets
    var current = 1;
    let steps = $("fieldset").length;

    _this.validateCampaignName()
    
    $(".next").click(function () {
      _this.nextStep()
    });
  }

  validateCampaignName(){
    var _this = this;
    window.Parsley.addValidator('validatecampaignname', {
      validateString: function(value){
        let xhr = $.ajax(`linkedalliance_campaign/valid_client_name?clientName=${value}`)
        return xhr.then(function(data) {
          if (data.status == 200) {
            _this.loadingTarget.classList.add("d-none");
            _this.tickTarget.classList.remove("d-none");
            return true
          }else{
            _this.tickTarget.classList.add("d-none");
            _this.loadingTarget.classList.add("d-none");
            return $.Deferred().reject("Campaign name is already taken. Please try another campaign name");
          }
        }).catch(function(e) {
          if (e == "Campaign name is already taken. Please try another campaign name") {
            return $.Deferred().reject("Campaign name is already taken. Please try another campaign name")
          }else{
            return true
          }
        });
      },
      messages: {
         en: 'Campaign name is already taken. Please try another campaign name',
      }
    });
  }

  readCookie(name){
    let data = this.getCookie(name)
    let result = {};

    data = data.split('%');
    for (let i in data) {
        const cur = data[i].split('=');
        result[cur[0]] = cur[1];
    }
    return result;
  }

  addCampaignMessages(){
    let _this = this
    $(_this.campaignFormTarget).parsley().whenValidate({
      group: 'block-1'
    })
    .done(()=>{
      _this.nextStep()
    })
  }

  saveCampaign(){
    $(this.campaignFormTarget).parsley().whenValidate({
      group: 'block-3'
    })
    .done(()=>{
      this.loaderTarget.classList.remove("d-none");
      Rails.fire(this.campaignFormTarget, 'submit')
    })    
  }

  handleResults(event){
    let _this = this
    const xhr = event.detail[0];
    let res = JSON.parse(xhr);
    console.log(res)
    if (res.campaign == 'created' ) {
      Swal.fire({
        title: 'Campaign Setup Complete',
        text: 'Information on it will take 24-48h collecting leads and then will begin',
        icon: 'success',
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Ok',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          _this.campaignNameTarget.innerHTML = 'New Linkedalliance Campaign'
          _this.campaignStatusTarget.innerHTML = 'Activated'
          _this.campaignStatusTarget.classList.add('text-success')
          _this.nextStep()
        }
      })
    }
  }

  updateProgressbar(stepnum) {
    let progressSteps = document.querySelectorAll(".progress-step");

    progressSteps.forEach((progressStep, idx) => {
      if (idx < stepnum + 1) {
        progressStep.classList.add("progress-step-active");
      } else {
        progressStep.classList.remove("progress-step-active");
      }
    });
  }

  nextStep(){
    let _this = this
    
    $(_this.campaignFormTarget).parsley().whenValidate({
      group: `block-${_this.stepnum}`
    })
    .done(()=>{
      _this.stepnum++
      _this.updateProgressbar(_this.stepnum);
      _this.current_fs = $(document.querySelectorAll("fieldset")[this.stepnum - 1])
      _this.next_fs = $(document.querySelectorAll("fieldset")[this.stepnum])
      //Add Class Active
      $("#progressbar .progress-step")
        .eq($("fieldset").index(this.next_fs))
        .addClass("active");

      //show the next fieldset
      this.next_fs.show();
      //hide the current fieldset with style
      this.current_fs.animate(
        { opacity: 0 },
        {
          step: function (now) {
            // for making fielset appear animation
            _this.opacity = 1 - now;

            _this.current_fs.css({
              display: "none",
              position: "relative",
            });
            _this.next_fs.css({ opacity: _this.opacity });
          },
          duration: 500,
        }
      );
    })
  }
  
  getCookie(cname) {
    let name = cname + "=";

    var splitCookie = document.cookie.split(";");
    // let splitCookie = decodedCookie.split(';');
    for (let count = 0; count < splitCookie.length; count++) {
      let splitted = splitCookie[count];
      while (splitted.charAt(0) == " ") {
        splitted = splitted.substring(1);
      }
      if (splitted.indexOf(name) == 0) {
        return splitted.substring(name.length, splitted.length);
      }
    }
    return "";
  }

  delete_cookie(name) {
    document.cookie = name +'=;domain=leadrequests.com; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  connectAccount() {
    this.loaderTarget.classList.remove("d-none");
    Rails.ajax({
      type: "POST",
      url: `linkedalliance_plan/subscription`,
      data: "interval=linkedalliance_month",
      dataType: "json",
      success: (res) => {
        if (res.status == 404) {
          window.location.href = res.path;
        }
      },
    });
  }

  toggleAlert() {
    let campaign_name_length =
      document.getElementById("campaign_name").value.length;
    this.tickTarget.classList.add("d-none");
    if (campaign_name_length < 3 && campaign_name_length > -1) {
      this.alertTarget.innerHTML = "Too Short";
      this.alertTarget.classList.remove("d-none");
    } else {
      this.alertTarget.innerHTML = "Campaign name already Exist";
      this.alertTarget.classList.add("d-none");
      this.debouncedCampaign();
    }
  }
}
