// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant struccd e within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'src/stylesheets/application.scss'
require("@rails/activestorage").start()
require("channels")
require("jquery")
import "@fortawesome/fontawesome-free/js/all";
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import "@fortawesome/fontawesome-free/js/all";
import "bootstrap-toggle";
import 'popper.js';
import 'bootstrap';
import Rails from 'rails-ujs'
require("chartkick")
require("chart.js")
import Turbolinks from "turbolinks"
Rails.start()
import flatpickr from "flatpickr";
require("flatpickr/dist/flatpickr.css")
Turbolinks.start()

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

