import Swal from 'sweetalert2/dist/sweetalert2.js'
import "parsleyjs";
import { Controller } from "stimulus";
import Rails from "rails-ujs";

export default class extends Controller {
  static targets = [
            "clientMessagesForm",
            "clientName",
            "messageTextarea",
            "followUpMessageTextarea",
            "submitBtn",
            "messageForm",
            "previousFollowUpMessage",
            "previousMessage",
            "date",
            "scrollingDiv",
            "loader"
          ];

  connect() {
    console.log("Connected");
  }

  getMessage() {
    
    var _this = this;
    if (_this.hasClientNameTarget){

      var clientName = _this.clientNameTarget.value;
      _this.clientNameTarget.disabled = true;
    }else
    {
      var clientName =" "
    }
    
    if (clientName == "") {
      _this.messageFormTarget.classList.add('d-none');
      return;
    }

    
    if (_this.hasClientNameTarget)
    {
      $.ajax({
        url: "/messages/client_messages",
        type: "GET",
        dataType: "json",
        data: { client_name: clientName, date_range: _this.dateTarget.value},
      }).done(function (data) {
        Swal.close()
        _this.clientNameTarget.disabled = false;
        _this.messageTextareaTarget.value = data.message;
        _this.followUpMessageTextareaTarget.value = data.followUpMessage;
        if( data.table !== undefined)
          _this.insertData(data["table"])
        if( data.previousFollowUp !== undefined && data.previousMessage !== undefined ){
          _this.previousFollowUpMessageTarget.innerHTML = data.previousFollowUp;
          _this.previousMessageTarget.innerHTML = data.previousMessage;
        }
        _this.messageFormTarget.classList.remove('d-none');
      });
    }
    $.ajax({
      url: "/messages/get_table",
      type: "GET",
      dataType: "json",
      data: { client_name: clientName, date_range: _this.dateTarget.value},
    }).done(function (data) {
      if( data.table !== undefined)
      {
        document.getElementsByTagName('tbody')[0].innerHTML=''
        _this.insertData(data["table"],data["paginate"])
      }
    });
    Swal.fire({
      title: "Please wait...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  updateMessages(){
    this.submitBtnTarget.disabled = true;

    if (this.messageTextareaTarget.value.length > 250)
      this.submitBtnTarget.disabled = false;

    Rails.fire(this.clientMessagesFormTarget, 'submit');
    Swal.fire({
      title: "Please wait...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  handleResults(){
    Swal.close()
    Swal.fire({
        title: 'Success!',
        text: 'Your messages have been updated',
        icon: 'success',
      })
    let _this = this
    const [data, status, xhr] = event.detail
    let res = JSON.parse(xhr.response)

    _this.submitBtnTarget.disabled = false;
    
    if (_this.hasClientNameTarget)
      _this.clientNameTarget.disabled = false;
    
    _this.previousFollowUpMessageTarget.innerHTML = res.previousFollowUp;
    _this.previousMessageTarget.innerHTML = res.previousMessage;
  }

  insertData(data,pagi){
    Swal.close()
    var tbodyRef = document.getElementsByClassName('scrollabe')[0];
    var par=tbodyRef.parentElement

    tbodyRef.innerHTML=tbodyRef.innerHTML+data
    var pagidiv=document.getElementById("pagi");
    pagidiv.innerHTML=pagi
    this.loaderTarget.classList.add("d-none");
    
    
    
    
  }
  scroll()
  {
    var max_height=this.scrollingDivTarget.scrollHeight
    if($('a[rel="next"]')[1]==undefined)
    return
    
    var _url=$('a[rel="next"]')[1].href
    var _this=this
    if(max_height-$(".table-wrapper").scrollTop()<1500)
    {
      _this.loaderTarget.classList.remove("d-none");
      
      
      $.ajax({
        url: _url,
        type: "GET",
        dataType: "json",
        async: false,
      }).done(function (data) {
        _this.insertData(data["table"],data["paginate"])
        _this.loaderTarget.classList.add("d-none");
      })
    }
  }
}
