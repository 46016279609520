import { Controller } from "stimulus";
import Rails from "rails-ujs";

export default class extends Controller {
  static targets = ["loader"];

  connect() {}

  monthlySubscription() {
    this.loaderTarget.classList.remove("d-none");
    Rails.ajax({
      type: "POST",
      url: `plan/subscription`,
      data: "interval=month",
      dataType: "json",
      success: (res) => {
        this.loaderTarget.classList.add("d-none");
        if (res.status == 404) {
          window.location.href = res.path;
        }
      },
    });
  }

  yearlySubscription() {
    this.loaderTarget.classList.remove("d-none");
    Rails.ajax({
      type: "POST",
      url: `plan/subscription`,
      data: "interval=year",
      dataType: "json",
      success: (res) => {
        this.loaderTarget.classList.add("d-none");
        if (res.status == 404) {
          window.location.href = res.path;
        }
      },
    });
  }

  linkedAllinaceMonthlySubscription() {
    this.loaderTarget.classList.remove("d-none");
    Rails.ajax({
      type: "POST",
      url: `linkedalliance_plan/subscription`,
      data: "interval=linkedalliance_month",
      dataType: "json",
      success: (res) => {
        this.loaderTarget.classList.add("d-none");
        if (res.status == 404) {
          window.location.href = res.path;
        }
      },
    });
  }

  linkedAllinaceYearlySubscription() {
    this.loaderTarget.classList.remove("d-none");
    Rails.ajax({
      type: "POST",
      url: `linkedalliance_plan/subscription`,
      data: "interval=linkedalliance_year",
      dataType: "json",
      success: (res) => {
        this.loaderTarget.classList.add("d-none");
        if (res.status == 404) {
          window.location.href = res.path;
        }
      },
    });
  }

  connectLinkedInMonthlySubscription() {
    this.loaderTarget.classList.remove("d-none");
    window.location.href = "/connect_linkedin";
  }

  connectLinkedInYearlySubscription() {
    this.loaderTarget.classList.remove("d-none");
    window.location.href = "/connect_linkedin";
  }
}
