import { Controller } from "stimulus";
import _ from "lodash";
import Rails from "rails-ujs";
import Choices from "choices.js";

export default class extends Controller {
  static targets = [
    "industrySpinner",
    "industryDropdown",
    "geoDropdown",
    "seniorLevelDropdown",
    "headcountDropdown",
    "functionDropdown",
    "roleDropdown",
  ];

  connect(){
    this.createDropdowns()
    this.optionsLoading = false;

    let _this = this;

    $(".choices-box .choices__input").keyup(
      _.debounce(function (event) {
        let el = event.currentTarget;
        let select = el.parentElement.querySelector("select");
        _this.insertChoices(el, select);
        _this.clearOptions(select);
      }, 500)
    );
  }

  createDropdowns() {
    this.options = {
      addItems: true,
      removeItems: true,
      removeItemButton: true,
      searchChoices: true,
      duplicateItemsAllowed: false,
    };

    this.industryDropdown = new Choices(
      this.industryDropdownTarget,
      this.options
    );

    this.geoDropdown = new Choices(this.geoDropdownTarget, this.options);

    this.seniorLevelDropdown = new Choices(
      this.seniorLevelDropdownTarget,
      this.options
    );

    this.headcountDropdown = new Choices(
      this.headcountDropdownTarget,
      this.options
    );

    this.functionDropdown = new Choices(
      this.functionDropdownTarget,
      this.options
    );

    this.roleDropdown = new Choices(this.roleDropdownTarget, this.options);
  }

  insertChoices(el, select) {
    let _this = this;
    // Fetch options with ajax call
    if (_this.optionsLoading) return;

    _this.optionsLoading = true;
    el.readOnly = true;

    _this.toggleSpinner(select);

    Rails.ajax({
      url: `/targeting/get_options?column=${select.dataset.dropdown}&value=${el.value}`,
      type: "get",
      data: "",
      success: (res) => {
        _this.optionsLoading = false;
        if (res.options.length > 0) {
          switch (select.dataset.dropdown) {
            case "geo":
              _this.geoDropdown.setChoices(
                res.options,
                "value",
                "label",
                false
              );
              break;
            case "role":
              _this.roleDropdown.setChoices(
                res.options,
                "value",
                "label",
                false
              );
              break;
          }
        }

        _this.toggleSpinner(select);
        el.readOnly = false;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  toggleSpinner(select) {
    $(`.${select.dataset.dropdown}-spinner`).toggleClass("d-none");
  }

  clearOptions(select) {
    switch (select.dataset.dropdown) {
      case "industry":
        this.industryDropdown.clearChoices();
        break;
      case "geo":
        this.geoDropdown.clearChoices();
        break;
      case "senior_level":
        this.seniorLevelDropdown.clearChoices();
        break;
      case "function":
        this.functionDropdown.clearChoices();
        break;
      case "headcount":
        this.headcountDropdown.clearChoices();
        break;
      case "role":
        this.roleDropdown.clearChoices();
        break;
    }
  }
}