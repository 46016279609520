import { Controller } from "stimulus";
import Rails from "rails-ujs";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default class extends Controller {
  static targets = [
    "adminClient",
    "form",
    "submitBtn",
    "accessBtn",
    "subscription",
    "formAccess",
  ];

  connect() {}

  getSubscriptions() {
    var _this = this;
    var userCheckboxes = document.querySelectorAll("input[type='checkbox']");
    var toggle_div = document.getElementById("toggle-div-access");
    if (this.subscriptionTarget.value == "") {
      toggle_div.classList.add("toggle");
      this.accessBtnTarget.classList.add("toggle");
      return;
    }
    _this.subscriptionTarget.disabled = true;
    _this.accessBtnTarget.disabled = true;

    $.ajax({
      url: "/permissions/users",
      type: "GET",
      dataType: "json",
      data: { subscription_name: _this.subscriptionTarget.value },
      success: function (data) {
        for (var i = 0; i < userCheckboxes.length; i++) {
          if (data.user_ids.includes(userCheckboxes[i].value))
            userCheckboxes[i].checked = true;
          else userCheckboxes[i].checked = false;
        }
        toggle_div.classList.remove("toggle");
        _this.accessBtnTarget.classList.remove("toggle");
        _this.subscriptionTarget.disabled = false;
        _this.accessBtnTarget.disabled = false;
      },
      error: function (err) {
        _this.subscriptionTarget.disabled = false;
        _this.accessBtnTarget.disabled = false;
        Swal.fire({
          title: "Error!",
          text: "User not found",
          icon: "error",
        });
      },
    });
  }

  accessTab() {
    Rails.fire(this.formAccessTarget, "submit");
    this.subscriptionTarget.disabled = true;
    this.accessBtnTarget.disabled = true;
    Swal.fire({
      title: "Please wait...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  getClients() {
    var _this = this;
    var clientCheckboxes = document.querySelectorAll("input[id='client-code']");
    var toggle_div = document.getElementById("toggle-div");
    if (this.adminClientTarget.value == "") {
      toggle_div.classList.add("toggle");
      this.submitBtnTarget.classList.add("toggle");
      return;
    }
    _this.adminClientTarget.disabled = true;
    _this.submitBtnTarget.disabled = true;

    $.ajax({
      url: "/permissions/clients",
      type: "GET",
      dataType: "json",
      data: { client_name: _this.adminClientTarget.value },
      success: function (data) {
        for (var i = 0; i < clientCheckboxes.length; i++) {
          if (data.clients.includes(clientCheckboxes[i].value))
            clientCheckboxes[i].checked = true;
          else clientCheckboxes[i].checked = false;
        }
        toggle_div.classList.remove("toggle");
        _this.submitBtnTarget.classList.remove("toggle");
        _this.adminClientTarget.disabled = false;
        _this.submitBtnTarget.disabled = false;
      },
      error: function (err) {
        _this.adminClientTarget.disabled = false;
        _this.submitBtnTarget.disabled = false;
        Swal.fire({
          title: "Error!",
          text: "User not found",
          icon: "error",
        });
      },
    });
  }

  assignPermissions() {
    Rails.fire(this.formTarget, "submit");
    this.adminClientTarget.disabled = true;
    this.submitBtnTarget.disabled = true;
    Swal.fire({
      title: "Please wait...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  handleResponse() {
    Swal.close();
    const xhr = event.detail[0];
    let res = JSON.parse(xhr.response);
    if (res.status == 400) {
      Swal.fire({
        title: "Error!",
        text: "User does not exist",
        icon: "error",
      });
    } else if (res.status == 200) {
      Swal.fire({
        title: "Success!",
        text: "Privileges have been assigned to user",
        icon: "success",
      });
    }
    this.adminClientTarget.disabled = false;
    this.submitBtnTarget.disabled = false;
  }

  handleResponseAccess() {
    Swal.close();
    const xhr = event.detail[0];
    let res = JSON.parse(xhr.response);
    if (res.status == 400) {
      Swal.fire({
        title: "Error!",
        text: "User does not exist",
        icon: "error",
      });
    } else if (res.status == 200) {
      Swal.fire({
        title: "Success!",
        text: "Subscriptions have been assigned to user",
        icon: "success",
      });
    }
    this.subscriptionTarget.disabled = false;
    this.accessBtnTarget.disabled = false;
  }
}
