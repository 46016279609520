import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ['date']

  connect(){
    if(this.hasDateTarget)
    this.dateTarget.flatpickr({mode: "range"});
  }
}
