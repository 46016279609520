import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ['form']
  connect(){
  }
  update(){
    Rails.fire(this.formTarget, 'submit')
  }
}
